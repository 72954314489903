<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("form.about") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.about_us_information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">{{
                      $t("form.about_us")
                    }}</label>
                    <textarea
                      class="form-control form-control-alternative"
                      v-model="about.aboutus"
                    >
                    </textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">{{
                      $t("form.support")
                    }}</label>
                    <textarea
                      class="form-control form-control-alternative"
                      v-model="about.support"
                    >
                    </textarea>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.facebook')"
                      :placeholder="$t('form.facebook')"
                      input-classes="form-control-alternative"
                      v-model="about.Facebook"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.whatsApp')"
                      :placeholder="$t('form.whatsApp')"
                      input-classes="form-control-alternative"
                      v-model="about.WhatsApp"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.linkedIn')"
                      :placeholder="$t('form.linkedIn')"
                      input-classes="form-control-alternative"
                      v-model="about.LinkedIn"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.twitter')"
                      :placeholder="$t('form.twitter')"
                      input-classes="form-control-alternative"
                      v-model="about.Twitter"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.snapChat')"
                      :placeholder="$t('form.snapChat')"
                      input-classes="form-control-alternative"
                      v-model="about.SnapChat"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.save')"
                @click.prevent="update"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "about",
  data() {
    return {
      loading: false,
      about: {},
      validationErrors: [],
    };
  },
  created() {
    this.getAbout("admin/administration/aboutus/index");
  },
  methods: {
    getAbout: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.about = response.data.data[0];
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update About us
    update: function () {
      this.loading = true;
      this.about._method = "put";
      axios
        .post("admin/administration/aboutus/update", this.about)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
